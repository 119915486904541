import '@sendbird/uikit-react/dist/index.css'
import { useState } from 'react'
import styles from './App.module.scss'
import { AuthProvider } from './auth'
import Widget from './Widget'

function Toggle({ onClick }: { onClick: () => void }) {
  return (
    <button className={styles.toggle} onClick={onClick}>
      <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="" />
    </button>
  )
}

function App() {
  const [showWidget, setShowWidget] = useState(true)

  return (
    <AuthProvider>
      <div className={styles.container}>
        {showWidget && <Widget />}
        <Toggle onClick={() => setShowWidget((v) => !v)} />
      </div>
    </AuthProvider>
  )
}

export default App
