import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const createContext = <A,>(name: string) => {
  const context = React.createContext<A | undefined>(undefined)

  const useValue = () => {
    const v = useContext(context)
    if (!v) throw new Error(`${name} must be inside a provider with a value`)

    return v
  }

  return [useValue, context.Provider] as const
}

/* types */
type Auth = {
  connection?: any
  appId?: string
  einsteinEnabled?: boolean
  error?:
    | 'NOT_SUPPORTED'
    | 'NOT_FOUND'
    | 'APP_ID_NOT_SET'
    | 'ACCESS_DECLINED'
    | Error
}

type Setting = { appId: string; einsteinEnabled: boolean }

type ContextValue = [Auth, (v: Auth) => void]

/* context */
export const [useAuthContextValue, Provider] =
  createContext<ContextValue>('auth')

export const useAuth = () => {
  const [auth] = useAuthContextValue()

  return auth
}

export const useSignout = () => {
  const [, setAuth] = useAuthContextValue()

  return useCallback(() => {
    const { jsforce } = window as any
    jsforce.browser.logout()
    localStorage.removeItem('sendbird-application-setting')
    setAuth({ ...getInitialAuth(), connection: undefined })
  }, [setAuth])
}

const getConnection = () => {
  const { jsforce } = window as any
  return jsforce.browser.connection
}

const getApplicationSetting = () => {
  const value = localStorage.getItem('sendbird-application-setting')
  return value ? (JSON.parse(value) as Setting) : undefined
}

const getInitialAuth = () => {
  const setting = getApplicationSetting()
  const connection = getConnection()

  return { ...setting, connection }
}

const isNotSupported = (error: unknown) => {
  if (!(error instanceof Error)) return false
  return error.message.includes(`'sendbird__Setting__c' is not supported.`)
}

const isAccessDeclined = (error: unknown) => {
  if (!(error instanceof Error)) return false
  return error.message.includes(`Access Declined`)
}

/* provider */
export function AuthProvider({ children }: { children: ReactNode }) {
  const contextValue = useState<Auth>(() => getInitialAuth())
  const [auth, setAuth] = contextValue
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const setting = {
      appId: auth.appId,
      einsteinEnabled: auth.einsteinEnabled,
    }
    const value = setting.appId ? JSON.stringify(setting) : ''
    localStorage.setItem('sendbird-application-setting', value)
  }, [auth.appId, auth.einsteinEnabled])

  useEffect(() => {
    const { jsforce } = window as any
    jsforce.browser.init({
      clientId:
        '3MVG9tSqyyAXNH5IC4A49X7OGn5RFvkGn6cZka2YKgu3I1LCMz28ZVLXmNYxwIcRmPqJ8ujzMh1Em2CGVceE4',
      redirectUri: `${window.location.origin}/`,
    })

    jsforce.browser.on('connect', async (connection: any) => {
      try {
        const result = await connection.query(
          "Select sendbird__ApplicationId__c, sendbird__EnableEinsteinBots__c, sendbird__EinsteinBotsId__c from sendbird__Setting__c where Name = 'SB-0000' limit 1"
        )
        if (result.records.length === 0) {
          setAuth((prev) => ({ ...prev, error: 'NOT_FOUND', connection }))
          return
        }

        const [
          {
            sendbird__ApplicationId__c: appId,
            sendbird__EnableEinsteinBots__c: botsEnabled,
            sendbird__EinsteinBotsId__c: botId,
          },
        ] = result.records
        if (!appId) {
          setAuth((prev) => ({ ...prev, error: 'APP_ID_NOT_SET', connection }))
          return
        }
        setAuth((prev) => ({
          ...prev,
          appId,
          einsteinEnabled: botsEnabled && !!botId,
          connection,
          error: undefined,
        }))
      } catch (error) {
        if (isAccessDeclined(error)) {
          setAuth((prev) => ({ ...prev, error: 'ACCESS_DECLINED' }))
          return
        }

        if (isNotSupported(error)) {
          setAuth((prev) => ({ ...prev, error: 'NOT_SUPPORTED' }))
          return
        }

        setAuth((prev) => ({ ...prev, error: error as any }))
      }
    })

    jsforce.browser.on('disconnect', () => {
      setAuth((prev) => ({ ...prev, connection: undefined, error: undefined }))
    })
  }, [setAuth])

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return <Provider value={contextValue}>{loaded && children}</Provider>
}
